import { useEffect,useState } from "react";
import DamageTypeChart from './DamageTypeChart';
import api from './api/posts';
function HomeAdmin({userName,refreshFailed,setRefreshFailed,dhtStat,setDhtStat,stats,setStats,accessToken,setAccessToken,userFullName}) {

  let e=undefined;
  if(stats.AssesmentCount>0)
    {
       e=<>
       <div className="album ">
        <div className="stat border border-primary d-flex flex-column justify-content-center align-items-center statCover">
                    <div className="infoGroupx ">
                        <h1 className="statinfo ">{`${stats.AssesmentCount}`}</h1>
                        <h1 className="statlabel">{`ASSESMENTS`}</h1>
                    </div>
                    <div className="infoGroupx marginTop">
                        <h1 className="statinfo">{`${stats.TeamCount}`}</h1>
                        <h1 className="statlabel">{`TEAMS`}</h1>
                    </div>
                    <div className="infoGroupx marginTop">
                        <h1 className="statinfo">{`${stats.VillageCount}`}</h1>
                        <h1 className="statlabel">{`VILLAGES`}</h1>
                    </div>    
                    <div className="sday">COMPLETED</div>            
        </div>
        <div className="stat border border-primary d-flex flex-column justify-content-center align-items-center statCover">
                    <div className="infoGroupx ">
                        <h1 className="statinfo ">{`${stats.AssesmentCountToday}`}</h1>
                        <h1 className="statlabel">{`ASSESMENTS`}</h1>
                    </div>
                    <div className="infoGroupx marginTop">
                        <h1 className="statinfo">{`${stats.TeamCountToday}`}</h1>
                        <h1 className="statlabel">{`TEAMS`}</h1>
                    </div>
                    <div className="infoGroupx marginTop">
                        <h1 className="statinfo">{`${stats.VillageCountToday}`}</h1>
                        <h1 className="statlabel">{`VILLAGES`}</h1>
                    </div>    
                    <div className="sday">TODAY</div>            
        </div>
        <div className="stat chartcover statCover">
          {(dhtStat.length!==0) && <DamageTypeChart
            data={dhtStat} 
          ></DamageTypeChart>}
        </div>
        
       </div> 
       
       
        </>;
    }
    else{
      e=(<p>Welcome, <span className="text text-info display-4">{userFullName}</span></p>);
    }
    useEffect(()=>{
      const fetchPosts=async ()=>{
        //alert('fetch');
              try{
                  const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                  setStats(response.data);  
                  console.log(JSON.stringify(response.data))        
                  //alert('using old AT succeed'+JSON.stringify(response.data));              
              }
              catch(err)
              {
                try{
                  const config = { withCredentials: true };
                  const x=await api.get('/getAccessToken',config);
                  //alert(JSON.stringify(x['data']['accessToken']));
                  
                  const nat=x['data']['accessToken'];
                  setAccessToken(nat);
                  setRefreshFailed(false);  
                  //console.log(`ne at ${nat}`);
                  //console.log(`Second at:${accessToken}`);
                  try{       
                      const x=await api.get('/stats',{headers:{'Authorization':`Bearer ${nat}`}});
                      //alert('using new AT succeed'+JSON.stringify(x.data));
                      //console.log(JSON.stringify(x.data));             
                      setStats(x.data); 
                   }
                   catch(err)
                   {
                    alert('refresh failed unusual'+JSON.stringify(err));
                    setStats({});
                   }
                }
                catch(err)
                {
                  alert('refresh failed');
                  //setError(JSON.stringify(err));

                  //alert('using old AT faild');             
                  setStats({});
                  setRefreshFailed(true);
                }
                
              }
      }
      //fetchPosts();
      // Initial fetch
      fetchPosts();

    // Set up interval to refresh every 10 seconds
    const intervalId = setInterval(() => {
        fetchPosts();
        //alert('refreshed');
    }, 20000); // 10,000 milliseconds = 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
    },[]);
    useEffect(()=>{
      const fetchPosts2=async ()=>{
        //alert('fetch');
              try{
                  const response=await api.get('/statsdht',{headers:{'Authorization':`Bearer ${accessToken}`}});
                  setDhtStat(response.data);  
                  console.log(JSON.stringify(response.data))        
                  //alert('using old AT succeed'+JSON.stringify(response.data));              
              }
              catch(err)
              {
                try{
                  const config = { withCredentials: true };
                  const x=await api.get('/getAccessToken',config);
                  //alert(JSON.stringify(x['data']['accessToken']));
                  
                  const nat=x['data']['accessToken'];
                  setAccessToken(nat);
                  setRefreshFailed(false);  
                  //console.log(`ne at ${nat}`);
                  //console.log(`Second at:${accessToken}`);
                  try{       
                      const x=await api.get('/statsdht',{headers:{'Authorization':`Bearer ${nat}`}});
                      //alert('using new AT succeed'+JSON.stringify(x.data));
                      //console.log(JSON.stringify(x.data));             
                      setDhtStat(x.data); 
                   }
                   catch(err)
                   {
                    alert('refresh failed unusual'+JSON.stringify(err));
                    setDhtStat({});
                   }
                }
                catch(err)
                {
                  alert('refresh failed');
                  //setError(JSON.stringify(err));

                  //alert('using old AT faild');             
                  setDhtStat({});
                  setRefreshFailed(true);
                }
                
              }
      }
      //fetchPosts();
      // Initial fetch
      fetchPosts2();

    // Set up interval to refresh every 10 seconds
    const intervalId2 = setInterval(() => {
        fetchPosts2();
        //alert('refreshed');
    }, 20000); // 10,000 milliseconds = 10 seconds

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId2);
    },[]);
    return (
      <main className='home album'>
              {e}
      </main>
    )
  }
  
  export default HomeAdmin