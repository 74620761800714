
import { useParams } from 'react-router-dom'
import { useEffect } from 'react';
function EditPost({posts,editPostTitle,editPostBody,setEditPostTitle,setEditPostBody,handleEdit}) {
    const {id}=useParams();
    const post=posts.find((post)=>{
        return (post.id.toString()===id.toString());
    })
    useEffect(()=>{
         if(post)
            {
                setEditPostTitle(post.title);
                setEditPostBody(post.body);
            }
    },[])
    return (
      <main>
        <h3>Edit post</h3>
        <form className='newPost' onSubmit={(e)=>{e.preventDefault()}}>
        <label htmlFor='txtPostTitle'>Title:</label>
        <input 
        type='text'
        id='txtPostTitle'
        required
        value={editPostTitle}
        onChange={(e)=>{setEditPostTitle(e.target.value)}}
        />
        <label htmlFor='txtPostBody'>Post Body:</label>
        <textarea
        id='txtPostBody'
        required
        value={editPostBody}
        onChange={(e)=>{setEditPostBody(e.target.value)}}
        />
        <button className="button"
          onClick={()=>{handleEdit(id)}}
        >
          UPDATE
        </button>
        </form>
      </main>
    )
  }
  
  export default EditPost