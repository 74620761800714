import { useEffect,useState } from "react";
import api from './api/posts';
import Feed from "./Feed"
function AdminSearch({posts,searchString}) {
  let e=undefined;
  if(posts.length)
    {
      //alert(posts[0].images.length);
      const newPosts= posts.filter(post => {
        return (
          post.name.toLowerCase().includes(searchString.toLowerCase()) ||
          post.village.toLowerCase().includes(searchString.toLowerCase()) ||
          post.assesmentType.toLowerCase().includes(searchString.toLowerCase())
        );
      });
      if(newPosts.length)
      {
        e= <Feed posts={newPosts} type={'admin'}></Feed>
      }
      else{
        e=<p>No Match Found!</p>;
      }
      
    }
    else{
      e=<p>No Match Found!</p>;
    }

    
    return (
      <main className='home album'>
              {e}
      </main>
    )
  }
  
  export default AdminSearch