import { useEffect,useState } from "react";
import api from './api/posts';
import Feed from "./Feed";
import Spinner from 'react-bootstrap/Spinner';

function AdminSearchPanel({setRefreshFailed,accessToken,setAccessToken,searchStringAdmin,setSearchStringAdmin,searchTeam,setSearchTeam,searchPosts,setSearchPosts,isSearchPage,setIsSearchPage}) 
{
  const [isLoading,setIsloading]=useState(true);
  const [teams,setTeams]=useState([]);
  
  let options = teams.map(user => ({
    value: user.userName,
    text: `${user.name}, ${user.team ? user.team : 'No Team'}, ${user.userName}`
  }));

  options=[{value:'-1', text:'SELECT TEAM'},...options];

  const fetchPosts=async ()=>{
    //alert('fetch');
          
  }
  const HandleTeamChange=async (e)=>{
    setIsloading(true);
    setSearchTeam(e.target.value);
    try{
      //const response=await api.get('/employees');
      const response=await api.post('/employees/handleGetAllPostsOf',{userName:e.target.value},{headers:{'Authorization':`Bearer ${accessToken}`}});
      //console.log(`uuuu`); 
      //alert(`${JSON.stringify(response.data)}`);
      setSearchPosts(response.data);                
  }
  catch(err)
  {
    try{
      const config = { withCredentials: true };
      const x=await api.get('/getAccessToken',config);
      //alert(JSON.stringify(x['data']['accessToken']));
      
      const nat=x['data']['accessToken'];
      setAccessToken(nat);
      setRefreshFailed(false);  
      //console.log(`ne at ${nat}`);
      //console.log(`Second at:${accessToken}`);
      try{       
          const x=await api.post('/employees/handleGetAllPostsOf',{userName:e.target.value},{headers:{'Authorization':`Bearer ${nat}`}});
          setSearchPosts(x.data); 
       }
       catch(err)
       {
        alert('refresh failed unusual'+JSON.stringify(err));
        setSearchPosts([]);
       }
    }
    catch(err)
    {
      //alert('refresh failed');
      //setError(JSON.stringify(err));
      setSearchPosts([]);
      setRefreshFailed(true);
    }
    
  }finally{
    console.log('ok');
  }
    setIsloading(false);
  }

  useEffect(()=>{
    setIsSearchPage(true);
    const fetchPosts=async ()=>{
            try{
                const response=await api.post('/users/getAllTeams',{headers:{'Authorization':`Bearer ${accessToken}`}});
                //console.log(`uuuu`); 
                //alert(`${JSON.stringify(response.data)}`);
                setTeams(response.data);                
            }
            catch(err)
            {
              try{
                const config = { withCredentials: true };
                const x=await api.get('/getAccessToken',config);
                //alert(JSON.stringify(x['data']['accessToken']));
                
                const nat=x['data']['accessToken'];
                setAccessToken(nat);
                setRefreshFailed(false);  
                //console.log(`ne at ${nat}`);
                //console.log(`Second at:${accessToken}`);
                try{       
                    const x=await api.post('/users/getAllTeams',{headers:{'Authorization':`Bearer ${nat}`}});
                    //alert(`${JSON.stringify(x.data)}`);
                    setTeams(x.data);     
                 }
                 catch(err)
                 {
                  //alert('refresh failed unusual'+JSON.stringify(err));
                  setTeams([]);
                 }
              }
              catch(err)
              {
                //alert('refresh failed');
                //setError(JSON.stringify(err));
                setTeams([]);
                setRefreshFailed(true);
              }
              
            }finally{
              console.log('ok');
              setIsloading(false);
            }
    }
   
    fetchPosts();
    return () => {
      setIsSearchPage(false);
        };
  },[]); 
  
    return (
      <>              
       <div className="fullWidth columndiv">
       <select className="smallOptionFont borderLess "  value={searchTeam} 
      onChange={(e)=>{HandleTeamChange(e)}}
        >
        {options.map((option, index) => (
            <option key={index} value={option.value}>
                {option.text}
            </option>
        ))}
        </select>
        <div className="fullWidth marginSearch">
        <input className="borderLess transparent pinkbottomborder whitetextcolor" type='text' placeholder="type to search"  value={searchStringAdmin} onChange={(e)=>{setSearchStringAdmin(e.target.value)}}></input>

        </div>
        
       </div>
       {isLoading && 
        <div className='spinneroverlay'>
          <div className='spinnerbody'>
            <Spinner animation="border" variant="info" />
            <p>Please wait. It might take a few seconds.</p>
          </div>           
        </div>}
      </>
    )
  }
  
  export default AdminSearchPanel