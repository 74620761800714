import Post from "./Post";
function UserDetails({posts=[],searchPosts=[],searchString,searchStringAdmin,userFullName,refreshFailed,userName='',isSearchPage}) {
  const newPosts= posts.filter(post => {
    return (
      post.name.toLowerCase().includes(searchString.toLowerCase()) ||
      post.village.toLowerCase().includes(searchString.toLowerCase()) ||
      post.assesmentType.toLowerCase().includes(searchString.toLowerCase())
    );
  });
  const newSPosts= searchPosts.filter(post => {
    return (
      post.name.toLowerCase().includes(searchStringAdmin.toLowerCase()) ||
      post.village.toLowerCase().includes(searchStringAdmin.toLowerCase()) ||
      post.assesmentType.toLowerCase().includes(searchStringAdmin.toLowerCase())
    );
  });
 
    return (
      <>              
       <div className="fullWidth UserDetails">
          {(userName.toLowerCase()!=="admin")&&(userName!=='') && <div className="text text-info d-flex justify-content-center align-items-center">
              <div className="smallcircle">{(refreshFailed)?'':newPosts.length}</div>
              <div className="smallblacktext">ASSESMENTS</div>
          </div> 
          }
          <div className="text text-info d-flex  justify-content-end align-items-center headertext">{(refreshFailed)?'':userFullName}</div>    
          {(userName.toLowerCase()==="admin") &&(isSearchPage)  && <div className="text text-info d-flex justify-content-center align-items-center">
              <div className="smallcircle">{(refreshFailed)?'':newSPosts.length}</div>
              <div className="smallblacktext">ASSESMENTS</div>
          </div> }
          
       </div>
      </>
    )
  }
  
  export default UserDetails