
import {useState} from 'react';
import { json, useParams,Link } from 'react-router-dom'
import { useEffect } from 'react';
import {useNavigate} from 'react-router-dom';
import api from './api/posts';
import SelectedImages from './SelectedImages'
import { APISERVERURL } from './APIServerURL';
import Spinner from 'react-bootstrap/Spinner';

function EditPost({posts,setPosts,accessToken,setAccessToken,villages}) {
  const [applicant_MobileNumber,setApplicant_MobileNumber]=useState('1');
  const [name,setName]=useState('1');
  
  const [dateofBirth,setDateofBirth]=useState('1');
  const [pAN_Card_Number,setPAN_Card_Number]=useState('1');
  
  
  const [voter_id,setVoter_id]=useState('1');
  const [revenue_Circle_Flood,setRevenue_Circle_Flood]=useState('1');
  
  const [fatherName,setFatherName]=useState('1');
  const [husbandName,setHusbandName]=useState('1');
  const [accountHolderName,setAccountHolderName]=useState('1');
  
  const [iFSCCode,setIFSCCode]=useState('1');
  const [bankAccountNumber,setBankAccountNumber]=useState('1');
  
  const [bankName,setBankName]=useState('1');
  const [bankBranchName,setBankBranchName]=useState('1');
  
  const [district_Name_Geography,setDistrict_Name_Geography]=useState('1');
  const [prog_off_revenue,setProg_off_revenue]=useState('1');
  
  const [districtName,setDistrictName]=useState('1');
  const [aadhaar_number,setAadhaar_number]=useState('1');
  
  const [assesmentType,setAssesmentType]=useState('NONE');
  const [rG_Flood_TreeSurvey,setRG_Flood_TreeSurvey]=useState('NONE');

  const [landSituated_Housing_TreeSurvey,setLandSituated_Housing_TreeSurvey]=useState('NONE');
  const [gender_TreeSurvey,setGender_TreeSurvey]=useState('NONE');

  const [typeOfHouse_TreeSurvey,setTypeOfHouse_TreeSurvey]=useState('NONE');
  const [village,setVillage]=useState('NONE');
  const [mouza,setMouza]=useState('NONE');
  const [rcircle,setRcircle]=useState('NONE');
  const [district,setDistrict]=useState('NONE');
  
  const [landType,setLandType]=useState('NONE');
  const [otherDetails,setOtherDetails]=useState('NONE');
  const [submittedBy,setSetSubmittedBy]=useState('NOT SPECIFIED');

  const [images,setImages]=useState([]);

  const [isLoading,setIsloading]=useState(false);

  const navigate=useNavigate();

  const {id}=useParams();
  
  const post=posts.find((post)=>{
      return (post._id.toString()===id.toString());
  })
  
  let ui=``;
  if(post && post.images && post.images.length!==0)
    {
       ui=post.images.map((image,index)=>{
        return      <div className='imgThumb' key={index}>
                      <img  src={`${APISERVERURL}/img/${image}`} alt="placeholder" />
                      <span><button className='btn btn-danger' style={{fontSize:'13px', letterSpacing:'1.1px'}} onClick={(e)=>{deletePhoto(e,image,post._id)}}>DELETE</button></span>
                    </div>
       })
    }
    else{
      ui=<p>
        {"0 photos"}
        </p>
    }
  useEffect(()=>{
       if(post)
          {
            setApplicant_MobileNumber(post.applicant_MobileNumber);
            setName(post.name);
            setDateofBirth(post.dateofBirth);
            setPAN_Card_Number(post.pAN_Card_Number);
            setVoter_id(post.voter_id);
            setRevenue_Circle_Flood(post.revenue_Circle_Flood);
            setFatherName(post.fatherName);
            setHusbandName(post.husbandName);
            setAccountHolderName(post.accountHolderName);
            setIFSCCode(post.iFSCCode);
            setBankAccountNumber(post.bankAccountNumber);
            setBankName(post.bankName);
            setBankBranchName(post.bankBranchName);
            setDistrict_Name_Geography(post.district_Name_Geography);
            setProg_off_revenue(post.prog_off_revenue);
            setDistrictName(post.districtName);
            setAadhaar_number(post.aadhaar_number);
            setAssesmentType(post.assesmentType);
            setRG_Flood_TreeSurvey(post.rG_Flood_TreeSurvey);
            setLandSituated_Housing_TreeSurvey(post.landSituated_Housing_TreeSurvey);
            setGender_TreeSurvey(post.gender_TreeSurvey);
            setTypeOfHouse_TreeSurvey(post.typeOfHouse_TreeSurvey);
            setVillage(post.village);
            setMouza(post.mouza);
            setRcircle(post.rcircle);
            setDistrict(post.district);     
            setLandType(post.landType);    

            setOtherDetails(post.otherDetails);     
            setSetSubmittedBy(post.submittedBy);    
          }
  },[])
  


  function getUID()
  {
     let timestamp=Date.now();
     return timestamp.toString(36);
  }
  const handleUpdate=async (e)=>
  {
      e.preventDefault();
      let formData = new FormData();    //formdata object

      formData.append("id",id); 
      formData.append("applicant_MobileNumber",applicant_MobileNumber); 
      formData.append("name",name);

      formData.append("dateofBirth", dateofBirth);
      formData.append("pAN_Card_Number", pAN_Card_Number);
      formData.append("voter_id", voter_id);
      formData.append("revenue_Circle_Flood", revenue_Circle_Flood);
      formData.append("fatherName", fatherName);
      formData.append("husbandName", husbandName);
      formData.append("accountHolderName", accountHolderName);
      formData.append("iFSCCode", iFSCCode);
      formData.append("bankAccountNumber", bankAccountNumber);
      formData.append("bankName", bankName);
      formData.append("bankBranchName", bankBranchName);
      formData.append("district_Name_Geography", district_Name_Geography);
      formData.append("prog_off_revenue", prog_off_revenue);
      formData.append("districtName", districtName);
      formData.append("aadhaar_number", aadhaar_number);
      formData.append("assesmentType", assesmentType);
      formData.append("rG_Flood_TreeSurvey", rG_Flood_TreeSurvey);
      formData.append("landSituated_Housing_TreeSurvey", landSituated_Housing_TreeSurvey);
      formData.append("gender_TreeSurvey", gender_TreeSurvey);
      formData.append("typeOfHouse_TreeSurvey", typeOfHouse_TreeSurvey);
      formData.append("village", village);
      formData.append("mouza", mouza);
      formData.append("rcircle", rcircle);
      formData.append("district", district);
      formData.append("landType", landType);

      formData.append("otherDetails", otherDetails);
      formData.append("submittedBy", submittedBy);
      
      Object.values(images).forEach(file=>{
        formData.append("images", file);
      });
      const config = {     
          headers: { 'content-type': 'multipart/form-data' }
      }

      setIsloading(true);
     try{
      const response= await api.put('/employees',formData,{headers:{'Authorization':`Bearer ${accessToken}`}});      
      setPosts(response.data);
      setIsloading(false);
      navigate(`/post/${id}`)
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          try{       
            const response= await api.put('/employees',formData,{headers:{'Authorization':`Bearer ${nat}`}}); 
            setPosts(response.data);   
            setIsloading(false);  
            navigate(`/post/${id}`)
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
            //navigate('/');
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }  
    }
 
  async function deletePhoto(e,img,postId)
    {
      e.preventDefault();
      try{
        //alert(img+" "+postId);
        //post request to deletePhoto wit {id,fileName}
        const response= await api.post('/employees/deletePhoto',{id:postId,fileName:img},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        setPosts(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          try{       
            const response= await api.post('/employees/deletePhoto',{id:postId,fileName:img},{headers:{'Authorization':`Bearer ${nat}`}});    
            setPosts(response.data);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            //navigate('/');
           }
        }
        catch(err)
        {
          alert('failed');
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }
    }
  async function getPdf1(e,img,postId)
    {
      e.preventDefault();
      try{
        //alert(img+" "+postId);
        //post request to deletePhoto wit {id,fileName}
        const response= await api.post('/employees/getPdf',{id:postId,fileName:img},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        alert(response);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          try{       
            const response= await api.post('/employees/getPdf',{id:postId,fileName:img},{headers:{'Authorization':`Bearer ${nat}`}});    
            alert(JSON.stringify(response));
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            //navigate('/');
           }
        }
        catch(err)
        {
          alert('failed');
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }
    }

  function getAssestmentTypeJsx()
  {
    return (<select className="borderLess" value={assesmentType} id='ddlAssesmentType'
      onChange={(e)=>{setAssesmentType(e.target.value)}}
>
      <option value="NONE">PLEASE SELECT</option>
      <option value="house">house</option>
      <option value="other">other</option>
      <option value="NA">Not Applicable</option>
      </select>);
  }
  function getRG_Flood_TreeSurveyJsx()
  {
    return (<select className="borderLess"  value={rG_Flood_TreeSurvey} id='ddlRG_Flood_TreeSurvey'
      onChange={(e)=>{setRG_Flood_TreeSurvey(e.target.value)}}
>
      <option value="NONE">PLEASE SELECT</option>
      <option value="Damaged Houses Fully">Damaged Houses Fully</option>
      <option value="Damaged Houses Severely">Damaged Houses Severely</option>
      <option value="Damaged Houses Partially">Damaged Houses Partially</option>
      <option value="Damaged Hut">Damaged Hut</option>
      <option value="Damaged Cattle Shed">Damaged Cattle Shed</option>
      <option value="NA">Not Applicable</option>
</select>
);
  }

  function getLandSituated_Housing_TreeSurveyJsx()
  {
    return (<select className="borderLess"  value={landSituated_Housing_TreeSurvey} id='ddlLandSituated_Housing_TreeSurvey'
      onChange={(e)=>{setLandSituated_Housing_TreeSurvey(e.target.value)}}
>
      <option value="NONE">PLEASE SELECT</option>
      <option value="Plain Area">Plain Area</option>
      <option value="Hilly Area">Hilly Area</option>
      <option value="NA">Not Applicable</option>
</select>

);
  }

  function getGender_TreeSurveyJsx()
  {
    return (<select className="borderLess" value={gender_TreeSurvey} id='ddlGender_TreeSurvey'
      onChange={(e)=>{setGender_TreeSurvey(e.target.value)}}
>
      <option value="NONE">PLEASE SELECT</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="NA">Not Applicable</option>
    </select>
);
  }

  function getTypeOfHouse_TreeSurveyJsx()
  {
    return (<select className="borderLess" value={typeOfHouse_TreeSurvey} id='ddlTypeOfHouse_TreeSurvey'
      onChange={(e)=>{setTypeOfHouse_TreeSurvey(e.target.value)}}
>
      <option value="NONE">PLEASE SELECT</option>
      <option value="Pakka House">Pakka House</option>
      <option value="Kachcha House">Kachcha House</option>
      <option value="NA">Not Applicable</option>
</select>
);
  }

  function getVillageJsx()
  {
    const ui=villages.map((v)=>{
      return <option value={v.Village}>{`${v.Village}-${v.Mouza},${v.RevenueCircle}`}</option>
    });
    return (<select className="borderLess" value={village} id='ddlVillage'
      onChange=
      {
        (e)=>
            {
              let vn=e.target.value;
              const vo=villages.find((v)=>{return v.Village===vn});
              if(vo)
              {
                setVillage(vo.Village);
                setMouza(vo.Mouza);
                setRcircle(vo.RevenueCircle);
                setDistrict(vo.District);
              }
              else{
                setVillage('NONE');
                setMouza('');
                setRcircle('');
                setDistrict('');
              }
            }
      }
    >
      <option value="NONE">PLEASE SELECT</option>
      {ui}
    </select>
);
  }
  
  function addToImageList(selectedFiles)
  {
    const newImages=[...images,...selectedFiles];
    /*for(let i=0;i<selectedFiles.length;i++)
      {
        let j=0;
        for(j=0;j<images.length;j++)
          {
              if(images[j].name===selectedFiles[i].name)
                {
                  console.log(selectedFiles[i].name);
                  break;
                }
          }
        if(j===images.length)
          {
            newImages.push(selectedFiles[i]);
          }        
      }
          */
      setImages(newImages);
      console.log(newImages.length);
  }
  function displaySelecttedFiles()
  {
    let x=`<div>`;
    let i=0;
    for(i=0;i<images.length;i++)
      {
        x=x+`<div className='imageListDisplay'>`;
        x=x+`<label>${images[i].name}</label>
        <button data-imagename=${images[i].name} className='button' onClick={(e)=>{removeSelectedFile(e)}}>X</button>`;
        x=x+`</div>`;
      }
    x=x+`</div>`;
    
    return(
      <div dangerouslySetInnerHTML={{ __html: x }} />
    );
  }
  function removeSelectedFile(e)
  {
    e.preventDefault();
    alert(e.target.getAttribute('data-imagename'));
  }

    return (
      <main>
         <div className="fullWidth centeredDiv"><h3>Edit Assesment</h3></div>
        <form className='newPost' onSubmit={handleUpdate}>

        <label htmlFor='ddlVillage'>Village:</label>
        {getVillageJsx()}

        <label className="marginTop"  htmlFor='ddlAssesmentType'>Assesment Type:</label>
        {getAssestmentTypeJsx()}

         
        <label className="marginTop" htmlFor='txtName'>Name:</label>
        <input 
        type='text' className="borderLess"
        id='txtName'
        required
        value={name}
        onChange={(e)=>{setName(e.target.value)}}
        />
        <label className="marginTop" htmlFor='ddlGender_TreeSurvey'>Gender:</label>
        {getGender_TreeSurveyJsx()}


        <label className="marginTop" htmlFor='txtApplicant_MobileNumber'>Applicant_MobileNumber:</label>
        <input 
        type='text' className="borderLess"
        id='txtApplicant_MobileNumber'
        required
        value={applicant_MobileNumber}
        onChange={(e)=>{setApplicant_MobileNumber(e.target.value)}}
        />

        

        <label className="marginTop" htmlFor='txtDateofBirth'>Date of Birth:</label>
        <input 
        type='text' className="borderLess"
        id='txtDateofBirth'
        required
        value={dateofBirth}
        onChange={(e)=>{setDateofBirth(e.target.value)}}
        />

<label className="marginTop" htmlFor='ddlLandSituated_Housing_TreeSurvey'>Land Situated</label>
{getLandSituated_Housing_TreeSurveyJsx()}

<label className="marginTop" htmlFor='txtLandType'>Land Type:</label>
        <input 
        type='text' className="borderLess"
        id='txtLandType'
        required
        value={landType}
        onChange={(e)=>{setLandType(e.target.value)}}
        /> 
        <label className="marginTop" htmlFor='ddlTypeOfHouse_TreeSurvey'>Type Of House</label>
        {getTypeOfHouse_TreeSurveyJsx()}




        
        <label className="marginTop" htmlFor='ddlRG_Flood_TreeSurvey'>Damage Type:</label>
        {getRG_Flood_TreeSurveyJsx()}

        
        
        <label className="marginTop" htmlFor='txtAadhaar_number'>Aadhaar_number:</label>
        <input 
        type='text' className="borderLess"
        id='txtAadhaar_number'
        required
        value={aadhaar_number}
        onChange={(e)=>{setAadhaar_number(e.target.value)}}
        />


        <label className="marginTop" htmlFor='txtPAN_Card_Number'>PAN_Card_Number:</label>
        <input 
        type='text' className="borderLess"
        id='txtPAN_Card_Number'
        required
        value={pAN_Card_Number}
        onChange={(e)=>{setPAN_Card_Number(e.target.value)}}
        />

        <label className="marginTop" htmlFor='txtVoter_id'>voter_id:</label>
        <input 
        type='text' className="borderLess"
        id='txtVoter_id'
        required
        value={voter_id}
        onChange={(e)=>{setVoter_id(e.target.value)}}
        />


                

        
        <label className="marginTop" htmlFor='txtFatherName'>FatherName:</label>
        <input 
        type='text' className="borderLess"
        id='txtFatherName'
        required
        value={fatherName}
        onChange={(e)=>{setFatherName(e.target.value)}}
        /> 

        <label className="marginTop" htmlFor='txtHusbandName'>HusbandName:</label>
        <input 
        type='text' className="borderLess"
        id='txtHusbandName'
        required
        value={husbandName}
        onChange={(e)=>{setHusbandName(e.target.value)}}
        /> 

        <label className="marginTop" htmlFor='txtAccountHolderName'>AccountHolderName:</label>
        <input 
        type='text' className="borderLess"
        id='txtAccountHolderName'
        required
        value={accountHolderName}
        onChange={(e)=>{setAccountHolderName(e.target.value)}}
        /> 

        <label className="marginTop" htmlFor='txtIFSCCode'>IFSCCode:</label>
        <input 
        type='text' className="borderLess"
        id='txtIFSCCode'
        required
        value={iFSCCode}
        onChange={(e)=>{setIFSCCode(e.target.value)}}
        /> 


        <label className="marginTop" htmlFor='txtBankAccountNumber'>BankAccountNumber:</label>
        <input 
        type='text' className="borderLess"
        id='txtBankAccountNumber'
        required
        value={bankAccountNumber}
        onChange={(e)=>{setBankAccountNumber(e.target.value)}}
        /> 


        <label className="marginTop" htmlFor='txtBankName'>BankName:</label>
        <input 
        type='text' className="borderLess"
        id='txtBankName'
        required
        value={bankName}
        onChange={(e)=>{setBankName(e.target.value)}}
        /> 


        <label className="marginTop" htmlFor='txtBankBranchName'>BankBranchName:</label>
        <input 
        type='text' className="borderLess"
        id='txtBankBranchName'
        required
        value={bankBranchName}
        onChange={(e)=>{setBankBranchName(e.target.value)}}
        /> 


        

     



        <label className="marginTop" htmlFor='txtOtherDetails'>Other Details:</label>
        <textarea className="borderLess" rows="4"
        id='txtOtherDetails'
        required
        value={otherDetails}
        onChange={(e)=>{setOtherDetails(e.target.value)}}
        /> 

        <label className="marginTop" htmlFor='txtSubmittedBy'>Submitted By:</label>
        <input 
        type='text' className="borderLess"
        id='txtSubmittedBy'
        required
        value={submittedBy}
        onChange={(e)=>{setSetSubmittedBy(e.target.value)}}
        />


        <label className="headertext marginTop">Uploaded Photos:</label>
        <div className='album'>
          {ui}
        </div>

        <input className="marginTop" type='file' id="fuPhotos" name="uploadedPhotos" multiple
        accept="image/png, image/jpg, image/jpeg"
        onChange={(e)=>{addToImageList(e.target.files)}}
        ></input>
        <SelectedImages
        images={images}
        setImages={setImages}
        >
        </SelectedImages>
        
        <div className="secondaryNav marginTop">
            <Link to={`/`}><button className='btn btn-success' style={{fontSize:'13px', letterSpacing:'1.1px'}}>HOME</button></Link>
            <button className='btn btn-primary' type='submit' style={{fontSize:'13px', letterSpacing:'1.1px'}}>UPDATE</button>
        </div>
        </form>
        {isLoading && 
        <div className='spinneroverlay'>
          <div className='spinnerbody'>
            <Spinner animation="border" variant="info" />
            <p>Please wait. It might take a few seconds.</p>
          </div>           
        </div>}
      </main>
    )
  }
  
  export default EditPost