function Header({title}) {
  const image="./nirupanwebbannerfile.png";
  return (
    <header className="header">
        <div className='imgbanner'>             
        <img  src={require(`${image}`)} alt="placeholder" />
        </div>
  </header>
  )
}

export default Header