import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import Home from './Home';
import NewPost from './NewPost';
import PostPage from './PostPage';
import About from './About';
import NotFound from './NotFound';
import {Route,Routes,BrowserRouter as Router,useNavigate} from 'react-router-dom';
import {useState,useEffect} from 'react'
import api from './api/posts';
import EditPost from './EditPostOld';
import LoginUI from './LoginUI';
import ProtectedRoute from './ProtectedRoute';

function App() {  
  const [posts,setPosts]=useState([]);  
  const [accessToken,setAccessToken]=useState(''); 
  const [isAuthenticated,setIsAuthenticated]=useState(false); 

  const [searchString,setSearchString]=useState('');
  const [searchResult,setSearchResult]=useState([]);
  const [editPostTitle,setEditPostTitle]=useState('');
  const [editPostBody,setEditPostBody]=useState('');

  const Navigate=useNavigate();
  useEffect(()=>{
    const fetchPosts=async ()=>{
            try{
                const response=await api.get('/employees');
                //console.log(`uuuu`); 
                //alert(`${JSON.stringify(response.data)}`);
                setPosts(response.data);
            }
            catch(err)
            {
               console.log(`Error:${err.message}`);     
            }
    }
    fetchPosts();
    console.log('fetching...');
  },[])
  
  const handleDelete=async (id)=>{
    try{
      await api.delete('/posts/'+id.toString());
      const filteredPosts=posts.filter(
        (post)=>{
          return post.id!=id
        }
       )
       setPosts(filteredPosts);
       Navigate('/');
    }
    catch(err)
    {
         console.log(err.message);
    }
     
  }
  const handleEdit=async (id)=>{
    console.log(`editing post at id=${id}`);
    let tdate=new Date();
    const datetime=tdate.toISOString();
    const newPost={
      id,
      title:editPostTitle,
      datetime,
      body:editPostBody
     }; 
     console.log(JSON.stringify(newPost)); 
     try{
        const response=await api.put('/posts/'+id.toString(),newPost);
        console.log(response.data);
        setPosts(posts.map(
        (post)=>{
          return(post.id===id?{...response.data}:post);
        }
       ));
     }
     catch(err)
     {
      console.log(err.message);
     } 
      
     setEditPostTitle('');
     setEditPostBody('');
  }

  useEffect(()=>{
    /*const filteredPosts=posts.filter((post)=>{
      return post.body.toLowerCase().includes(searchString.toLowerCase())
              || post.title.toLowerCase().includes(searchString.toLowerCase())
    });
    //console.log(JSON.stringify(filteredPosts));
    */
    setSearchResult(posts);
  },[posts,searchString])

  return (
      <div className="App">
        <Header title="Flood Assesment App"></Header>
        <Nav       
        searchString={searchString}
        setSearchString={setSearchString}
        />
          <Routes>
           
                      <Route  path='/home' element={isAuthenticated ?<Home 
                        posts={searchResult}
                        />:<LoginUI
                        setAccessToken={setAccessToken}/>}>            
                        </Route>

                        <Route  path='/post' element={<NewPost
                        posts={posts}
                        setPosts={setPosts}
                        />}>            
                        </Route>


                        <Route  path='/post/:id' element={<PostPage posts={posts} handleDelete={handleDelete}></PostPage>}></Route>
            
                        <Route  path='/edit/:id' element={<EditPost
                        posts={posts}
                        editPostTitle={editPostTitle}
                        editPostBody={editPostBody}
                        setEditPostTitle={setEditPostTitle}
                        setEditPostBody={setEditPostBody}
                        handleEdit={handleEdit}
                        />}>            
                        </Route>

              <Route  path='/about' element={isAuthenticated?<About></About> :<LoginUI
                setAccessToken={setAccessToken}/>   }></Route>
              <Route path='*' element={<NotFound></NotFound>}></Route>

              <Route  path='/' element={ <LoginUI
                setAccessToken={setAccessToken}/>}>            
              </Route>
          
          </Routes>
        <Footer></Footer>
      </div>
  );
}

export default App;
