import { Link } from 'react-router-dom';
function Nav({searchString,setSearchString}) {
    return (
      <nav className="navuser">
        <div className="search-areauser">
        <input className="borderLess transparent pinkbottomborder whitetextcolor" type='text' placeholder="type to search" onChange={(e)=>{setSearchString(e.target.value)}} value={searchString}></input>
        </div>
        <div className="links-areauser">
          <ul>
              <li><Link className='navLinkuser' to="/">HOME</Link> </li>
              <li><Link className='navLinkuser' to="/post">NEW</Link></li>
              <li><Link className='navLinkuser' to="/report">REPORT</Link></li> 
              <li><Link className='navLinkuser' to="/about">ABOUT</Link></li> 
              <li><Link className='navLinkuser' to="/logout">LOGOUT</Link></li>
          </ul>
        </div>
      </nav>
    )
  }
  
  export default Nav