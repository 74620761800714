
import axios from './api/posts';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react'

import Spinner from 'react-bootstrap/Spinner';

function LoginUI({setAccessToken,setIsAuthenticated,userFullName,setUserFullName,refreshFailed,setRefreshFailed}){
  const [userName,setUserName]=useState('');
  const [password,setPassword]=useState('');
  const navigate=useNavigate();
  const [isLoading,setIsloading]=useState(false);
  async function handleLogin(e)
    {
      e.preventDefault();
      setIsloading(true);
      try{
            const config = { withCredentials: true };
            const data=await axios.post('/login',{
            userName:userName,
            password:password
            },config);
            //alert(JSON.stringify(data['data']['accesstoken']));
            setAccessToken(data['data']['accesstoken']);
            setUserFullName(data['data']['name']);
            setIsAuthenticated(true);
            setRefreshFailed(false);
            setUserName('');
            setPassword('');
            navigate('/');   
                       
      }
      catch(err)
      {
        //alert(err);
        setAccessToken('');
        setIsAuthenticated(false);
        setRefreshFailed(true);
        alert('Login failed!');
      }
      finally
      {
        setIsloading(false);     
      }
    }

    return (  
      <main>
        <form className="loginForm">
          <div className="loginDiv">
            <label>User Name:</label>
            <input className="borderLess" type='text' placeholder="Registered Mobile Number" onChange={(e)=>{setUserName(e.target.value)}} value={userName}></input>
            <label className="marginTop">Password</label>
            <input className="borderLess" type='password' onChange={(e)=>{setPassword(e.target.value)}} value={password}></input>
            <div className="marginTop fullWidth loginButtonyNav"><button className="btn btn-primary" name='btnLogin' onClick={handleLogin}>LOGIN</button></div>
          </div>
        </form>
        {isLoading && 
        <div className='spinneroverlay'>
          <div className='spinnerbody'>
            <Spinner animation="border" variant="info" />
            <p>Please wait. It might take a few seconds.</p>
          </div>           
        </div>}
      </main> 

       );
}
export default LoginUI;
