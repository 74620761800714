import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({isAuthenticated}) => {
   if(!isAuthenticated)
      {
       return <Navigate to="/login" />
      }
   return <Outlet></Outlet>
}

export default ProtectedRoute;