import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useEffect } from "react";
import api from './api/posts';
const CustomTooltip = ({ payload, label }) => {
    if (payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{label}</p>
          <ul>
            {payload.map((entry, index) => (
              <li key={index} style={{ color: entry.stroke }}>
                {entry.name}: {entry.value}
              </li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  };

function DamageTypeChart({data}) {

    //const data = dhtStat;
      
      return (
         <>
         <p>DAMAGE TYPE CHART</p>
      <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="label" style={{
        fontSize: '.51rem',
        fontFamily: 'Times New Roman',
    }}/>
              <YAxis tickCount={50} style={{
        fontSize: '.71rem',
        fontFamily: 'Times New Roman',
    }}/>
              <Tooltip content={<CustomTooltip />}  />
              <Legend
        layout="vertical"      // Left, center, or right
      />
              <Bar dataKey="Kaliabor" stackId="a" fill="#8884d8" />
              <Bar dataKey="Samaguri" stackId="a" fill="#82ca9d" />   
              <Bar dataKey="NONE" stackId="a" fill="#FF0000" />   

            </BarChart>
          </ResponsiveContainer>
      
          </>          
        );
}
export default  DamageTypeChart ;
