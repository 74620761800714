import Header from './Header';
import Nav from './Nav';
import Footer from './Footer';
import Home from './Home';
import NewPost from './NewPost';
import PostPage from './PostPage';
import About from './About';
import NotFound from './NotFound';
import {Route,Routes,BrowserRouter as Router,useNavigate, Navigate} from 'react-router-dom';
import {useState,useEffect} from 'react'
import api from './api/posts';
import EditPost from './EditPost';
import LoginUI from './LoginUI';
import LogOutUI from './LogOutUI';
import ProtectedRoute from './ProtectedRoute';
import Report from './Report';
import UserDetails from './UserDetails';
import Spinner from 'react-bootstrap/Spinner';


import MoreOptions from './MoreOptions';
import NavAdmin from './NavAdmin';
import UserNav from './UserNav';
import HomeAdmin from './HomeAdmin';
import AdminSearch from './AdminSearch';
import AdminSearchPanle from './AdminSearchPanel';

function App() {  
  const [posts,setPosts]=useState([]);  
  const [accessToken,setAccessToken]=useState(''); 
  const [isAuthenticated,setIsAuthenticated]=useState(false); 
  const [isAppLoaded,setIsAppLoaded]=useState(false); 

  const [searchString,setSearchString]=useState('');
  const [searchResult,setSearchResult]=useState([]);
  const [editPostTitle,setEditPostTitle]=useState('');
  const [editPostBody,setEditPostBody]=useState('');

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);



  const [userFullName,setUserFullName]=useState('');
  const [userName,setUserName]=useState('');
  const [refreshFailed,setRefreshFailed]=useState(true);
  
  const[stats,setStats]=useState({AssesmentCount:0,VillageCount:0,TeamCount:0,AssesmentCountToday:0,VillageCountToday:0,TeamCountToday:0})
  const[dhtStat,setDhtStat]=useState({})

  const [searchStringAdmin,setSearchStringAdmin]=useState('');
  const [searchTeam,setSearchTeam]=useState('');
  const [searchPosts,setSearchPosts]=useState([]);
  const [isSearchPage,setIsSearchPage]=useState(false);

  const villages=[
    {
      "Village": "Baghmari",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Bagori",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Beselimari",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    }, 
    {
      "Village": "Bihdubi No.1",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Bihdubi No.2",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Borghuli No.2",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Borghuli No.3",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Borali Gaon",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Chatial",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Dakhin Deopani",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Dakhinpat",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Darigoji",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Deopani",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Dolgaon",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Dulal Madhab",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Gakhirkhaity No.3",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Gakhirkhaity No.4",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Garhaguri",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Halowa Gaon",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Harmoti No.1",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Harmoti No.2",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Hatigaon",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Hatimura No.1",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Hatimura No.2",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Jakhalabandha Town",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Jawkota",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Keribakori",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Khaloiati",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Kuwaritol",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Kuthari",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Langi Chook",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Madhatari",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Missa Tea Estate No.1",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Missamukh Gaon",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Missamukh Pam",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Moria Gaon",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Namgaon",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Najan",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Nijari Gaon",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Niz Borbhogia",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Niz Missamukh",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Niz Pubtharia",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "No 1 Bhurbandha",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "No 2 Bhurbandha",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Ouguri",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Pani Gaon",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Phulaguri Chang",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Pub Deopani",
      "Mouza": "Dwar Bagori",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Rahdhola",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Rangoloo",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Rowmari Beel",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Rowmari Doloni",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Sakmuthi",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Sarubhogia",
      "Mouza": "Chatial",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Sivasthan",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Sonari Gaon (Miribheti)",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Telia Chook",
      "Mouza": "Pubtharia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
    {
      "Village": "Uppar Borbhogia",
      "Mouza": "Borbhogia",
      "RevenueCircle": "Kaliabor",
      "District": "Nagaon"
    },
      {
        "Village": "Auniati Satra",
        "Mouza": "Bheleuguri",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bajiagaon",
        "Mouza": "Khatowal",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bhumuraguri",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bhurbandha Bazargaon",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bhogamur",
        "Mouza": "Khatowal",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bogamukh No 4",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bogamukh No 5",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bogamukh No 6",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Bogamukh No 7",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Borbalitup",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Brahmabil",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Chakitup",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Gatangagaon",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Hahchora Baraligaon",
        "Mouza": "Bheleuguri",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Kanuwamari",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Khalihamari",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Kuhumtali",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Majuligaon",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Mikirhat",
        "Mouza": "Bheleuguri",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Missa",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Mowamari",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "No 1 Bhurbandha (Samaguri)",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "No 1 Garumara",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "No 2 Bhurbandha (Samaguri)",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "No 2 Garumara",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "No 3 Bhurbandha (Samaguri)",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "No 4 Bhurbandha (Samaguri)",
        "Mouza": "Lawkhowa",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Niz Khatowal",
        "Mouza": "Khatowal",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Panigaon",
        "Mouza": "Khatowal",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },{
        "Village": "Paghali",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Puranigudam Town",
        "Mouza": "Chalchali",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Sialekhaity",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Sonaribali",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Samaguri Grant (Majarati)",
        "Mouza": "Bheleuguri",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Sutargaon",
        "Mouza": "Khatowal",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      },
      {
        "Village": "Tekeliputa",
        "Mouza": "Rangagora",
        "RevenueCircle": "Samaguri",
        "District": "Nagaon"
      }
    ];
    
  
  useEffect(()=>{
    const fetchAT=async ()=>{
          try{
            const config = { withCredentials: true };
            const x=await api.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['name'];
            const un=x['data']['userName'];
            setAccessToken(nat);   
            setIsAuthenticated(true);   
            setUserFullName(ufn);    
            setUserName(un);
                    
            setRefreshFailed(false);
            if(userName.toLowerCase()!=="admin")
            {
                  try{       
                    const x=await api.get('/employees',{headers:{'Authorization':`Bearer ${nat}`}});
                    setPosts(x.data);
                    //alert('read');
                }
                catch(err)
                {
                  alert('refresh failed unusual'+JSON.stringify(err));
                  //setAccessToken('');   
                  //setIsAuthenticated(false); 
                  //setUserFullName(''); 
                }
              }
              if(userName.toLowerCase()==="admin")
                {
                      try{       
                        const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        setStats(response.data);  
                        const response2=await api.get('/statsdht',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        setDhtStat(response2.data);  
                        //alert('read');
                    }
                    catch(err)
                    {
                      alert('refresh failed unusual'+JSON.stringify(err));
                      //setAccessToken('');   
                      //setIsAuthenticated(false); 
                      //setUserFullName(''); 
                    }
                  }
            }
            catch(err)
            {
              setAccessToken('');   
              setIsAuthenticated(false);    
              setUserFullName('');  
              setUserName('');  
              setRefreshFailed(true);              
            }
            finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
    }
    fetchAT();
  },[refreshFailed]);
  
  
  const handleDelete=async (id)=>{
    /*try{
      await api.delete('/posts/'+id.toString());
      const filteredPosts=posts.filter(
        (post)=>{
          return post.id!=id
        }
       )
       setPosts(filteredPosts);
       //Navigate('/');
    }
    catch(err)
    {
         console.log(err.message);
    }
     */
    let i=0;
  }
  const handleEdit=async (id)=>{
    console.log(`editing post at id=${id}`);
    let tdate=new Date();
    const datetime=tdate.toISOString();
    const newPost={
      id,
      title:editPostTitle,
      datetime,
      body:editPostBody
     }; 
     console.log(JSON.stringify(newPost)); 
     try{
        const response=await api.put('/posts/'+id.toString(),newPost);
        console.log(response.data);
        setPosts(posts.map(
        (post)=>{
          return(post.id===id?{...response.data}:post);
        }
       ));
     }
     catch(err)
     {
      console.log(err.message);
     } 
      
     setEditPostTitle('');
     setEditPostBody('');
  }

  useEffect(()=>{
    /*const filteredPosts=posts.filter((post)=>{
      return post.body.toLowerCase().includes(searchString.toLowerCase())
              || post.title.toLowerCase().includes(searchString.toLowerCase())
    });
    //console.log(JSON.stringify(filteredPosts));
    */
    setSearchResult(posts);
  },[posts,searchString])

  
  return (
    <Router>
      <div className="App">
        {((refreshFailed)||(userName.toLowerCase()==="admin"))&&<Header></Header>}
        { (isAppLoaded)&&!refreshFailed &&(userName.toLowerCase()==="admin")&& <NavAdmin       
          searchString={searchString}
          setSearchString={setSearchString}
          />}
          { (isAppLoaded)&&!refreshFailed &&(userName.toLowerCase()!=="admin")&& <Nav 
          searchString={searchString}
          setSearchString={setSearchString}
          />}
          { (isAppLoaded)&&refreshFailed&& <UserNav 
          searchString={searchString}
          setSearchString={setSearchString}
          />}

        { (isAppLoaded) &&!refreshFailed &&(userName.toLowerCase()==="admin")&&
          <>
          <UserDetails isSearchPage={isSearchPage} userName={userName} userFullName={userFullName} refreshFailed={refreshFailed} searchPosts={searchPosts} searchStringAdmin={searchStringAdmin} />
          <Routes>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated}/>}>
              <Route  path='/' element={<HomeAdmin 
                        stats={stats}
                        setStats={setStats} 
                        dhtStat={dhtStat}
                        setDhtStat={setDhtStat} 
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        userFullName={userFullName}
                        userName={userName}
                        setUserName={setUserName}
                        refreshFailed={refreshFailed}
                        setRefreshFailed={setRefreshFailed}
                        />}>            
              </Route>

              <Route  path='/post' element={<NewPost
                        posts={posts}
                        setPosts={setPosts}
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        villages={villages}
                        />}>            
              </Route>
              <Route  path='/post/:id' element={<PostPage 
                           posts={posts}
                           setPosts={setPosts}
                           accessToken={accessToken} 
                           setAccessToken={setAccessToken}                           
                           handleDelete={handleDelete}
                          />
                          }>                          
              </Route> 
              <Route  path='/searchpost/:id' element={<PostPage 
                           posts={searchPosts}
                           setPosts={setPosts}
                           accessToken={accessToken} 
                           setAccessToken={setAccessToken}                           
                           handleDelete={handleDelete}
                           mode={'admin'}
                          />
                          }>                          
              </Route>
            
              <Route  path='/edit/:id' element={<EditPost
                        posts={posts}
                        setPosts={setPosts}
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        villages={villages}
                        />}>            
              </Route>
              <Route path='/moreoptions' element={<MoreOptions
                        />}> 
              </Route>

              <Route path='/report' element={<Report
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        posts={posts}
                        userName={userName}
                        />}> 
              </Route>
              <Route path='/AdminSearch' element={<>
                        <AdminSearchPanle
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        setRefreshFailed={setRefreshFailed}
                        searchStringAdmin={searchStringAdmin}
                        setSearchStringAdmin={setSearchStringAdmin}
                        searchTeam={searchTeam}
                        setSearchTeam={setSearchTeam}
                        searchPosts={searchPosts}
                        setSearchPosts={setSearchPosts}
                        isSearchPage={isSearchPage}
                        setIsSearchPage={setIsSearchPage}
                        />
                        <AdminSearch
                        posts={searchPosts}
                        searchString={searchStringAdmin}
                        /></>}> 
              </Route>
          </Route>
         
        
          <Route  path='/about' element={<About></About>}></Route>
          <Route path='*' element={<NotFound></NotFound>}></Route>

          <Route  path='/login' element={!isAuthenticated? <LoginUI
                setRefreshFailed={setRefreshFailed} refreshFailed={refreshFailed} userFullName={userFullName} setUserFullName={setUserFullName} userName={userName} setUserName={setUserName} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>:<Navigate to="/" />}>            
          </Route>
          <Route  path='/logout' element={isAuthenticated? <LogOutUI
          setSearchResult={setSearchResult} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated} setRefreshFailed={setRefreshFailed}/>:<Navigate to="/Login" />}>            
          </Route>
        </Routes>
        </>
      }
      { (isAppLoaded) &&!refreshFailed && (userName.toLowerCase()!=="admin")&&
          <>
          <UserDetails userName={userName} posts={posts} searchString={searchString} userFullName={userFullName} refreshFailed={refreshFailed} />
          <Routes>
          <Route element={<ProtectedRoute isAuthenticated={isAuthenticated}/>}>
              <Route  path='/' element={<Home 
                        posts={searchResult}
                        setPosts={setPosts} 
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        userFullName={userFullName}
                        userName={userName}
                        setUserName={setUserName}
                        refreshFailed={refreshFailed}
                        setRefreshFailed={setRefreshFailed}
                        searchString={searchString}
                        />}>            
              </Route>

              <Route  path='/post' element={<NewPost
                        posts={posts}
                        setPosts={setPosts}
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        villages={villages}
                        />}>            
              </Route>
              <Route  path='/post/:id' element={<PostPage 
                           posts={posts}
                           setPosts={setPosts}
                           accessToken={accessToken} 
                           setAccessToken={setAccessToken} 
                          />
                          }>                          
              </Route>
            
              <Route  path='/edit/:id' element={<EditPost
                        posts={posts}
                        setPosts={setPosts}
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        villages={villages}
                        />}>            
              </Route>
              <Route path='/more' element={<MoreOptions
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        />}> 
              </Route>

              <Route path='/report' element={<Report
                        accessToken={accessToken} 
                        setAccessToken={setAccessToken}
                        posts={posts}
                        userName={userName}
                        />}> 
              </Route>
          </Route>
         
        
          <Route  path='/about' element={<About></About>}></Route>
          <Route path='*' element={<NotFound></NotFound>}></Route>

          <Route  path='/login' element={!isAuthenticated? <LoginUI
                setRefreshFailed={setRefreshFailed} refreshFailed={refreshFailed} userFullName={userFullName} setUserFullName={setUserFullName} userName={userName} setUserName={setUserName} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>:<Navigate to="/" />}>            
          </Route>
          <Route  path='/logout' element={isAuthenticated? <LogOutUI
          setSearchResult={setSearchResult} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated} setRefreshFailed={setRefreshFailed}/>:<Navigate to="/Login" />}>            
          </Route>
        </Routes>
        </>
      }
      
      {
        !isAppLoaded &&
          <div className='spinneroverlay'>
            <div className='spinnerbody'>
              <Spinner animation="border" variant="info" />
              <p>Please wait. It might take a few seconds.</p>
            </div>           
          </div>
      }
      {
       (isAppLoaded) && refreshFailed && 
        <>
        <Routes>
        <Route  path='/about' element={<About></About>}></Route>
          <Route path='*' element={ <LoginUI
                setRefreshFailed={setRefreshFailed} refreshFailed={refreshFailed} userFullName={userFullName} setUserFullName={setUserFullName} userName={userName} setUserName={setUserName} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>}>            
                </Route>

          <Route  path='/login' element={ <LoginUI
                setRefreshFailed={setRefreshFailed} refreshFailed={refreshFailed} userFullName={userFullName} setUserFullName={setUserFullName} userName={userName} setUserName={setUserName} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated}/>}>            
          </Route>
          <Route  path='/logout' element={ <LogOutUI
          setSearchResult={setSearchResult} setAccessToken={setAccessToken} setIsAuthenticated={setIsAuthenticated} setRefreshFailed={setRefreshFailed}/>}>            
          </Route>
        </Routes>
        </>
      }

        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
