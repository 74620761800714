import { Link } from 'react-router-dom';
function NavAdmin({searchString,setSearchString}) {
    return (
      <nav className="nav">
        <div className="search-area">
                 
        </div>
        <div className="links-area">
          <ul>
              <li><Link className='navLink' to="/">HOME</Link> </li>
              <li><Link className='navLink' to="/report">REPORT</Link></li> 
              <li><Link className='navLink' to="/AdminSearch">SEARCH</Link></li> 
              <li><Link className='navLink' to="/moreoptions">MORE</Link></li>
          </ul>
        </div>
      </nav>
    )
  }
  
  export default NavAdmin