import {Link} from 'react-router-dom';
import { APISERVERURL } from './APIServerURL';

const Post=({post,type})=>{

    let to=`/post/${post._id}`
    if(type!=='user')
    {
        to=`/searchpost/${post._id}`
    }
     /*return(<article className='post'>
            <Link className='Link' to={to}>
            <label>Assessment Type:</label>
            <p>
                {post.assesmentType}
            </p>
            <label>Applicant Name:</label>
            <p>
                { post.name}
            </p>
            </Link>
            
            <p>
                {
                    post.images.length+" PHOTOS"
                }
            </p>
            
        </article>  
    )*/  
    const imgName=(post.images && post.images.length>0)?post.images[0]:"default.png";
    return( 
        <div className='imgThumbHome overLay'>
            <Link className='Link' to={to}>                        
                        <img  src={`${APISERVERURL}/img/${imgName}`} alt="placeholder" />
                        <div className='imgInfo'>
                            <div className="infoGroup">
                                <p className='headerText gridLabel'>Assessment:</p>
                                <p className='infoText gridInfo'>
                                    {post.assesmentType}
                                </p>
                            </div>
                            <div className="infoGroup">
                                <p className='headerText gridLabel'>Applicant:</p>
                                <p className='infoText gridInfo'>
                                    { post.name}
                                </p>
                            </div>
                            <div className="infoGroup">
                                <p className='headerText gridLabel'>Village:</p>
                                <p className='infoText gridInfo'>
                                    { post.village}
                                </p>
                            </div>
                            <div className="infoGroupSingle">
                                <p className='infoText'>
                                    {
                                        post.images.length+" PHOTOS"
                                    }           
                                </p>
                            </div>
                        </div>
            </Link>
        </div>  
    )
}
export default Post