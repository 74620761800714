import { useEffect,useState } from "react";
import api from './api/posts';
import Feed from "./Feed"
function Home({searchString,setSearchString,userName,refreshFailed,setRefreshFailed,posts,setPosts,accessToken,setAccessToken,userFullName}) {
  //const [searchString,setSearchString] =useState('am');
  let e=undefined;
  if(posts.length)
    {
      //alert(posts[0].images.length);
      const newPosts= posts.filter(post => {
        return (
          post.name.toLowerCase().includes(searchString.toLowerCase()) ||
          post.village.toLowerCase().includes(searchString.toLowerCase()) ||
          post.assesmentType.toLowerCase().includes(searchString.toLowerCase())
        );
      });
       e=<Feed posts={newPosts}></Feed>
      
    }
    else{
      e=(<p>Welcome, <span className="text text-info display-4">{userFullName}</span></p>);
    }
    useEffect(()=>{
      const fetchPosts=async ()=>{
        //alert('fetch');
              try{
                  //const response=await api.get('/employees');
                  const response=await api.get('/employees',{headers:{'Authorization':`Bearer ${accessToken}`}});
                  //console.log(`uuuu`); 
                  //alert(`${JSON.stringify(response.data)}`);
                  setPosts(response.data);                
              }
              catch(err)
              {
                try{
                  const config = { withCredentials: true };
                  const x=await api.get('/getAccessToken',config);
                  //alert(JSON.stringify(x['data']['accessToken']));
                  
                  const nat=x['data']['accessToken'];
                  setAccessToken(nat);
                  setRefreshFailed(false);  
                  //console.log(`ne at ${nat}`);
                  //console.log(`Second at:${accessToken}`);
                  try{       
                      const x=await api.get('/employees',{headers:{'Authorization':`Bearer ${nat}`}});
                      setPosts(x.data); 
                   }
                   catch(err)
                   {
                    alert('refresh failed unusual'+JSON.stringify(err));
                    setPosts([]);
                   }
                }
                catch(err)
                {
                  //alert('refresh failed');
                  //setError(JSON.stringify(err));
                  setPosts([]);
                  setRefreshFailed(true);
                }
                
              }finally{
                console.log('ok');
              }
      }
      fetchPosts();
    },[accessToken]);
    return (
      <main className='home album'>
              {e}
      </main>
    )
  }
  
  export default Home