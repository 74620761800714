import Post from "./Post";
function Feed({posts,type='user'}) {
    return (
      <>              
       {
        posts.map((post)=>{
            return (
                <Post key={post.id} post={post} type={type}></Post>
            );
        })
       }     
      </>
    )
  }
  
  export default Feed