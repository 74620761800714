
function SelectedImages({images,setImages}) {
  function deleteSelectedImage(e,imgName)
  {
    e.preventDefault();
    const newImages=images.filter(
      (im)=>{
        return im.name!==imgName
      }
    )
    setImages(newImages);
  }
   const aImages=[];
   for(let i=0;i<images.length;i++)
    {
      aImages.push(images[i].name);
    }
    return (
      <>              
       {
        
        aImages.map((image,index)=>{
            return (
                <div className="fullWidth marginTopSmall" key={index} >
                  <p ><span>{image}
                  <button className="btn btn-danger" style={{
                    marginLeft: ".2rem"
                  }}
                  onClick={(e)=>{
                    deleteSelectedImage(e,image);
                  }}>X</button>
                    </span>
                  </p>                 
                  
                </div>
            );
        })
       }     
      </>
    )
  }
  
  export default SelectedImages