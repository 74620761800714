import { Link,useParams } from "react-router-dom";
import { APISERVERURL } from './APIServerURL';

function PostPage({posts,handleDelete}) {
  const { id }=useParams();
  const x='something...';
  let post=posts.find(post=>(post.id).toString()===id.toString());
  let ui=``;
  if(post && post.images && post.images.length!==0)
    {
       ui=post.images.map((image,index)=>{
        return <div className='imgThumb' key={index}>             
                <img  src={`${APISERVERURL}/img/${image}`} alt="placeholder" />
                <span></span>
              </div>
       })
    }
    else{
      ui=<p>
        {"0 photos"}
        </p>
    }
    return (
      <main>
        <article className='newPost'>
        <div className="fullWidth centeredDiv"><h3>View Assesment</h3></div>
          {
          post &&
            <>
              <label className="headertext">Applicant Mobile Number:</label>
              <p className="infotext">{post.applicant_MobileNumber}</p>

              <label className="headertext">Name:</label>
  <p className="infotext">{post.name}</p>

  <label className="headertext">Date of Birth:</label>
  <p className="infotext">{post.dateofBirth}</p>

  <label className="headertext">PAN Card Number:</label>
  <p className="infotext">{post.pAN_Card_Number}</p>

  <label className="headertext">Voter ID:</label>
  <p className="infotext">{post.voter_id}</p>

  <label className="headertext">Revenue Circle Flood:</label>
  <p className="infotext">{post.revenue_Circle_Flood}</p>

  <label className="headertext">Father's Name:</label>
  <p className="infotext">{post.fatherName}</p>

  <label className="headertext">Husband's Name:</label>
  <p className="infotext">{post.husbandName}</p>

  <label className="headertext">Account Holder Name:</label>
  <p className="infotext">{post.accountHolderName}</p>

  <label className="headertext">IFSC Code:</label>
  <p className="infotext">{post.iFSCCode}</p>

  <label className="headertext">Bank Account Number:</label>
  <p className="infotext">{post.bankAccountNumber}</p>

  <label className="headertext">Bank Name:</label>
  <p className="infotext">{post.bankName}</p>

  <label className="headertext">Bank Branch Name:</label>
  <p className="infotext">{post.bankBranchName}</p>

  <label className="headertext">District Name Geography:</label>
  <p className="infotext">{post.district_Name_Geography}</p>

  <label className="headertext">Program Officer Revenue:</label>
  <p className="infotext">{post.prog_off_revenue}</p>

  <label className="headertext">District Name:</label>
  <p className="infotext">{post.districtName}</p>

  <label className="headertext">Aadhaar Number:</label>
  <p className="infotext">{post.aadhaar_number}</p>

  <label className="headertext">Assessment Type:</label>
  <p className="infotext">{post.assesmentType}</p>

  <label className="headertext">RG Flood Tree Survey:</label>
  <p className="infotext">{post.rG_Flood_TreeSurvey}</p>

  <label className="headertext">Land Situated Housing Tree Survey:</label>
  <p className="infotext">{post.landSituated_Housing_TreeSurvey}</p>

  <label className="headertext">Gender Tree Survey:</label>
  <p className="infotext">{post.gender_TreeSurvey}</p>

  <label className="headertext">Type of House Tree Survey:</label>
  <p className="infotext">{post.typeOfHouse_TreeSurvey}</p>

  <label className="headertext">Village:</label>
  <p className="infotext">{post.village}</p>

  <label className="headertext">Uploaded Photos:</label>
  <div className='album'>
          {ui}
  </div>
               <div className="secondaryNav marginTop">
              <button className='btn btn-primary' onClick={()=>{handleDelete(id)}}>DELETE</button>
              <Link to={`/`}><button className='btn btn-success' >HOME</button></Link>
              <Link to={`/edit/${id}`}><button className='btn btn-primary' >EDIT</button></Link>
              </div>
            </>
          }
          {
          !post &&
            <>
              <p>
              Oops!! 404 page not found! Please visit <Link to='/'>our homepage</Link>
              </p> 
            </>
          }
        </article>        
      </main>
    )
  }
  
  export default PostPage