function About() {
    return (
      <main>
        <div className="aboutDiv">
          <div className="section">
            <h4 className="sectionhead uppercase mainhead scrollanim">
               ABOUT US
            </h4>
            <div className="sectionbody sectionbodytext scrollanim">
            Welcome to Nirupan, a web application for conducting Post-Flood House Damage Assessment Surveys. This initiative by the Kaliabor Sub-Divisional Administration aims to streamline the house damage survey process for field-level teams. Developed by YROS Software, Tezpur, Nirupan enhances efficiency and ease of use for officials during surveys.

            </div>
          </div>

          <div className="section">
            <h4 className="sectionhead uppercase scrollanim">
            Our Objective
            </h4>
            <div className="sectionbody sectionbodytext scrollanim">
           
            The primary goal of Nirupan is to enable easy and accurate data entry on smartphones via web browsers. Users can quickly capture information on damaged houses, simplifying the data collection process and reducing the time and effort needed to complete surveys.

            </div>
          </div>

          <div className="section">
            <h4 className="sectionhead uppercase scrollanim">
              Key Features
            </h4>
            <div className="sectionbody sectionbodytext">
            <ul className="fl">
  <li>
    <strong className="scrollanim">Secure and User-Friendly Login System</strong>
    <p className="scrollanim">Nirupan provides a user-friendly login system, ensuring a seamless entry into the application and efficient data entry mechanisms that streamline the workflow.</p>
  </li>
  <li>
    <strong className="scrollanim" >Government-Compliant Data Entry Form</strong>
    <p className="scrollanim">The data entry form in Nirupan is meticulously designed to conform to the government-prescribed house damage format, ensuring all necessary details are captured accurately and comprehensively.</p>
  </li>
  <li>
    <strong className="scrollanim">Photo Uploads</strong>
    <p className="scrollanim">Users have the facility in Nirupan to upload single or multiple photos taken during assessments, making it easy to document and share visual evidence of damages.</p>
  </li>
  <li>
    <strong className="scrollanim">Data Management</strong>
    <p className="scrollanim">Nirupan offers robust functionality for reviewing, editing, and deleting captured data and photos, allowing users to maintain accurate and up-to-date records.</p>
  </li>
  <li>
    <strong className="scrollanim">PDF Reports</strong>
    <p className="scrollanim">Users can generate and download detailed PDF reports in Nirupan, providing a convenient way to review and share collected data in a professional format.</p>
  </li>
  <li>
    <strong className="scrollanim">Advanced Admin Tools for Data Management and Live Statistics</strong>
    <p className="scrollanim">Admins have access to advanced features in Nirupan such as exporting data in Excel format and generating comprehensive reports, facilitating better data analysis and decision-making.</p>
  </li>
</ul>


            </div>
          </div>


          <div className="section">
            <h4 className="sectionhead uppercase scrollanim">
            Support Helplines
            </h4>
            <div className="sectionbody sectionbodytext ">
    <h5 className="sectionbodytextL2 scrollanim">For Survey related issues:</h5>
    <p  className="scrollanim">Please contact the respective officers for any issues or inquiries related to survey activities.</p>
    <ul className="fl f2">
      <li>
        <strong>Circle Officer, Samaguri</strong>
        <p>Contact: 97071 99532</p>
      </li>
      <li>
        <strong>Circle Officer, Kaliabor</strong>
        <p >Contact: 88767 14936</p>
      </li>
      <li>
        <strong>Circle Officer (A), Samaguri</strong>
        <p >Contact: 98544 17817</p>
      </li>
      <li>
        <strong>Field Officer, DDMA, Samaguri Rev. Circle</strong>
        <p >Contact: 84863 88916</p>
      </li>
      <li>
        <strong>Field Officer, DDMA, Kaliabor Rev. Circle</strong>
        <p >Contact: 60004 94043</p>
      </li>
    </ul>
  </div>
  <div className="sectionbody sectionbodytext">
    <h5 className="sectionbodytextL2">For App related technical issues:</h5>
    <p >For technical support and assistance with the app, please reach out to the provided contacts.</p>
    <ul className="fl f2">
      <li>
        <strong>Technical Support 1</strong>
        <p >Contact: 88129 84365</p>
      </li>
      <li>
        <strong>Technical Support 2</strong>
        <p >Contact: 97066 08877</p>
      </li>
    </ul>
  </div>
          </div>
          

          <div className="section">
            
            <div className="sectionbody yrostext">
           
            Nirupan is developed and maintained by YROS Software, Tezpur. For inquiries about new software development projects, including AI-based applications, website development, web app development, or mobile app development, please contact us at 789-692-2693 or email contactyros@gmail.com.

            </div>
          </div>


        </div>
      </main>
    )
  }
  
  export default About