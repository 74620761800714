import { Link,useParams } from "react-router-dom";
import { APISERVERURL } from './APIServerURL';
import api from './api/posts';
import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';

function Report({accessToken,setAccessToken,userName}) {
  const [isLoading,setIsloading]=useState(false);

    function downloadFile(fname)
    {
      fetch(`${APISERVERURL}/pdf/${fname}`).then((response) => {
        response.blob().then((blob) => {
         
            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);
                 
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fname;
            alink.click();
            setIsloading(false);
        });
      });
    }
    async function getCSV(e)
    {
      e.preventDefault();
      try{
        setIsloading(true);
        const response= await api.post('/employees/getCSV',{},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          try{       
            const response= await api.post('/employees/getCSV',{},{headers:{'Authorization':`Bearer ${nat}`}});    
            downloadFile(response.data);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
        }

      }
    }
    async function createBackUp(e)
    {
      e.preventDefault();
      try{
        setIsloading(true);
        const response= await api.post('/employees/createBackUp',{},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        alert('DONE. Please use your FTP client to download the images!');
        setIsloading(false);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          try{       
            const response= await api.post('/employees/createBackUp',{},{headers:{'Authorization':`Bearer ${nat}`}});    
            alert('DONE. Please use your FTP client to download the images!');
            setIsloading(false);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
        }

      }
    }

    return (
      <main>
        <article className='newPost'>
        <div className="fullWidth baseddDiv">
        <button className="btn btn-success" style={{fontSize:'13px', letterSpacing:'1.1px',marginLeft:'10px',width:'8rem',height:'8rem',borderRadius:'50%'}} onClick={(e)=>{getCSV(e)}}>DOWNLOAD CSV</button>
       
         
          {(userName.toLowerCase()==='admin')&&             
               
               <button className="btn btn-warning" style={{fontSize:'13px', letterSpacing:'1.1px',marginLeft:'10px',width:'8rem',height:'8rem',borderRadius:'50%'}} onClick={(e)=>{createBackUp(e)}}>CREATE BACKUP</button>
              
          }
          </div>
          {isLoading && 
          <div className='spinneroverlay'>
            <div className='spinnerbody'>
              <Spinner animation="border" variant="info" />
              <p>Please wait. It might take a few seconds.</p>
            </div>           
          </div>}
        </article>        
      </main>
    )
  }
  
  export default Report