import { Link,useParams,useNavigate, json} from "react-router-dom";
import { APISERVERURL } from './APIServerURL';
import api from './api/posts';
import { useState } from "react";
import Spinner from 'react-bootstrap/Spinner';

function PostPage({posts,accessToken,setAccessToken,mode='user'}) {
  //alert(JSON.stringify(posts));
  const [isLoading,setIsloading]=useState(false);
  const navigate = useNavigate();
  const { id }=useParams();
  const x='something...';
  let post=posts.find(post=>(post._id).toString()===id.toString());
  let ui=``;
  if(post && post.images && post.images.length!==0)
    {
       ui=post.images.map((image,index)=>{
        return <div className='imgThumb' key={index}>             
                <img  src={`${APISERVERURL}/img/${image}`} alt="placeholder" />
                <span></span>
              </div>
       })
    }
    else{
      ui=<p>
        {"0 photos"}
        </p>
    }
    async function handleDelete(e)
    {
      e.preventDefault();
     
      const isConfirmed = window.confirm('Are you sure you want to delete this item?');

        if (isConfirmed) {
          try{
            setIsloading(true);
            await api.post('/employees/deletePost',{id},{headers:{'Authorization':`Bearer ${accessToken}`}});       
            setIsloading(false); navigate('/');
          }
          catch(err)
          {
            try{
              const config = { withCredentials: true };
              const x=await api.get('/getAccessToken',config);
              const nat=x['data']['accessToken'];
              setAccessToken(nat);
              try{       
                 await api.post('/employees/deletePost',{id},{headers:{'Authorization':`Bearer ${nat}`}});
                setIsloading(false); 
                navigate('/');
               }
               catch(err)
               {
                alert('unusual error newposts.js'+err);
                setIsloading(false);
               }
            }
            catch(err)
            {
              alert('failed');
              setIsloading(false);
            }
    
          }
    
        } else {
          // Handle the case when user cancels
          console.log('Delete action cancelled.');
        }
    }
    function downloadFile(fname)
    {
      fetch(`${APISERVERURL}/pdf/${fname}`).then((response) => {
        response.blob().then((blob) => {
         
            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);
                 
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fname;
            alink.click();
            setIsloading(false);
        });
      });
    }
    async function getPdf(e,img,postId)
    {
      //alert('gett pdf ok1');
      e.preventDefault();
      try{
        //alert(img+" "+postId);
        //post request to deletePhoto wit {id,fileName}
        setIsloading(true);
        const response= await api.post('/employees/getPdf',{id:postId,fileName:img},{headers:{'Authorization':`Bearer ${accessToken}`}});    
        //alert(response.data);
        downloadFile(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await api.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          //alert('ok');
          try{       
            const response= await api.post('/employees/getPdf',{id:postId,fileName:img},{headers:{'Authorization':`Bearer ${nat}`}});    
            //alert(JSON.stringify(response.data));
            downloadFile(response.data);
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsloading(false);
            //alert(JSON.stringify(err));
            //navigate('/');
           }
        }
        catch(err)
        {
          alert('failed');
          setIsloading(false);
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }
    }

    return (
      <main>
        <article className='newPost'>
        <div className="fullWidth baseddDiv"><h3>View Assesment</h3> <button className="btn btn-success" style={{fontSize:'13px', letterSpacing:'1.1px',marginLeft:'10px'}} onClick={(e)=>{getPdf(e,'',post._id)}}>PDF</button></div>
          {
          post &&
            <>

           <label className="headertext">District:</label>
  <p className="infotext">{post.district}</p>

  <label className="headertext">Revenue Circle:</label>
  <p className="infotext">{post.rcircle}</p>

  <label className="headertext">Mouza:</label>
  <p className="infotext">{post.mouza}</p>

  <label className="headertext">Village:</label>
  <p className="infotext">{post.village}</p>

  
  

  


  <label className="headertext">Assessment Type:</label>
  <p className="infotext">{post.assesmentType}</p>

  <label className="headertext">Name:</label>
  <p className="infotext">{post.name}</p>

  <label className="headertext">Gender:</label>
  <p className="infotext">{post.gender_TreeSurvey}</p>

  <label className="headertext">Applicant Mobile Number:</label>
  <p className="infotext">{post.applicant_MobileNumber}</p>

  
  <label className="headertext">Date of Birth:</label>
  <p className="infotext">{post.dateofBirth}</p>

  <label className="headertext">Land Situated :</label>
  <p className="infotext">{post.landSituated_Housing_TreeSurvey}</p>


  <label className="headertext">Land Type:</label>
  <p className="infotext">{post.landType}</p>


  <label className="headertext">Type of House:</label>
  <p className="infotext">{post.typeOfHouse_TreeSurvey}</p>


  <label className="headertext">Damage Type:</label>
  <p className="infotext">{post.rG_Flood_TreeSurvey}</p>

  

  <label className="headertext">Aadhaar Number:</label>
  <p className="infotext">{post.aadhaar_number}</p>

  <label className="headertext">PAN Card Number:</label>
  <p className="infotext">{post.pAN_Card_Number}</p>

  <label className="headertext">Voter ID:</label>
  <p className="infotext">{post.voter_id}</p>

  
  
  <label className="headertext">Father's Name:</label>
  <p className="infotext">{post.fatherName}</p>

  <label className="headertext">Husband's Name:</label>
  <p className="infotext">{post.husbandName}</p>

  <label className="headertext">Account Holder Name:</label>
  <p className="infotext">{post.accountHolderName}</p>

  <label className="headertext">IFSC Code:</label>
  <p className="infotext">{post.iFSCCode}</p>

  <label className="headertext">Bank Account Number:</label>
  <p className="infotext">{post.bankAccountNumber}</p>

  <label className="headertext">Bank Name:</label>
  <p className="infotext">{post.bankName}</p>

  <label className="headertext">Bank Branch Name:</label>
  <p className="infotext">{post.bankBranchName}</p>

   

  <label className="headertext">Other Details:</label>
  <p className="infotext">{post.otherDetails}</p>


  <label className="headertext">Submitted By:</label>
  <p className="infotext">{post.submittedBy}</p>


  <label className="headertext">Uploaded Photos:</label>
  <div className='album'>
          {ui}
  </div>
              {(mode!=='admin') && <div className="secondaryNav marginTop">
              <button className='btn btn-primary' style={{fontSize:'13px', letterSpacing:'1.1px'}} onClick={(e)=>{handleDelete(e)}}>DELETE</button>
              <Link to={`/`}><button className='btn btn-success' style={{fontSize:'13px', letterSpacing:'1.1px'}}>HOME</button></Link>
              <Link to={`/edit/${id}`}><button className='btn btn-primary' style={{fontSize:'13px', letterSpacing:'1.1px'}}>EDIT</button></Link>
              </div>
              }
              {(mode==='admin') && <div className="secondaryNav2 marginTop">
              <Link to={`/AdminSearch`}><button className='btn btn-success' style={{fontSize:'13px', letterSpacing:'1.1px'}}>BACK</button></Link>
              </div>
              }
            </>
          }
          {
          !post &&
            <>
              <p>
              Oops!! 404 page not found! Please visit <Link to='/'>our homepage</Link>
              </p> 
            </>
          }
          {isLoading && 
          <div className='spinneroverlay'>
            <div className='spinnerbody'>
              <Spinner animation="border" variant="info" />
              <p>Please wait. It might take a few seconds.</p>
            </div>           
          </div>}
        </article>        
      </main>
    )
  }
  
  export default PostPage