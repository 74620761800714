import { Link } from 'react-router-dom';
function UserNav({searchString,setSearchString}) {
    return (
      <nav className="nav">
        <div className="search-area">
                 
        </div>
        <div className="links-area">
          <ul>             
              <li><Link className='navLink' to="/login">LOGIN</Link></li>
              <li><Link className='navLink' to="/about">ABOUT</Link></li> 
          </ul>
        </div>
      </nav>
    )
  }
  
  export default UserNav