import { Link } from 'react-router-dom';
function MoreOptions() {
  //const [searchString,setSearchString] =useState('am');
 
    
    return (
      <main>
      <article className='newPost'>
      <div className="fullWidth baseddDiv">
          <ul className='ul'>
              <li><Link className='navLinkBlack' to="/about">ABOUT</Link></li> 
              <li><Link className='navLinkBlack' to="/logout">LOGOUT</Link></li>
          </ul>
      </div>
      </article>
      </main>
    )
  }
  
  export default MoreOptions